import React from "react"
import styled from "styled-components"
import { sm, md, lg } from "mill/utils/breakpoints"
import { boxSizes } from "shared/utils/boxSizes"

const Bleed = styled.div`
  ${props => props.top && `margin-top: -${boxSizes.mobile[props.top]};`}
  ${props => props.right && `margin-right: -${boxSizes.mobile[props.right]};`}
  ${props => props.bottom && `margin-bottom: -${boxSizes.mobile[props.bottom]};`}
  ${props => props.left && `margin-left: -${boxSizes.mobile[props.left]};`}

  @media ${sm} {
    ${props => props.top && `margin-top: -${boxSizes.tablet[props.top]};`}
    ${props => props.right && `margin-right: -${boxSizes.tablet[props.right]};`}
    ${props => props.bottom && `margin-bottom: -${boxSizes.tablet[props.bottom]};`}
    ${props => props.left && `margin-left: -${boxSizes.tablet[props.left]};`}
  }

  @media ${md} {
    ${props => props.top && `margin-top: -${boxSizes.desktop[props.top]};`}
    ${props => props.right && `margin-right: -${boxSizes.desktop[props.right]};`}
    ${props => props.bottom && `margin-bottom: -${boxSizes.desktop[props.bottom]};`}
    ${props => props.left && `margin-left: -${boxSizes.desktop[props.left]};`}
  }

  @media ${lg} {
    ${props => props.top && `margin-top: -${boxSizes.wide[props.top]};`}
    ${props => props.right && `margin-right: -${boxSizes.wide[props.right]};`}
    ${props => props.bottom && `margin-bottom: -${boxSizes.wide[props.bottom]};`}
    ${props => props.left && `margin-left: -${boxSizes.wide[props.left]};`}
  }
`

const Component = ({ children, ...props }) => {
  return (
    <Bleed {...props}>
      {children}
    </Bleed>
  )
}

export default Component
