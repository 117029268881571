import React from "react"
import styled from "styled-components"
import zIndex from "shared/utils/zIndexLevels"

export const OverlayContainer = styled.div`
  position: relative;
`
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    content: "";
    background: ${props => props.background};
    opacity: ${props => props.opacity};
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  > * {
    opacity: 1;
    z-index: ${zIndex.base + 1};
  }
`

const OverlayComponent = ({ children, ...props }) => {
  return (
    <Overlay $background={props.background} {...props}>
      {children}
    </Overlay>
  )
}

OverlayComponent.defaultProps = {
  background: "#FFFFFF",
  opacity: 0.9
}

export default OverlayComponent
