import React from "react"
import styled from "styled-components"

const boxShadows = {
  small: "0px 1px 4px rgba(0, 0, 0, 0.04)"
}

const Card = styled.div`
  background: #fff;
  border-radius: 10px;

  // overrides
  ${props => props.backgroundColor && `background: ${props.backgroundColor};`}
  ${props =>
    props.boxShadow &&
    boxShadows[props.boxShadow] &&
    `box-shadow: ${boxShadows[props.boxShadow]}`}
`

const Component = ({ children, ...props }) => {
  return <Card {...props}>{children}</Card>
}

export default Component
