import React, { useContext } from "react"
import { darken } from "polished"
import { useQuery } from "@apollo/client"
import CurrentUserContext from "shared/utils/CurrentUserContext"
import styled from "styled-components"
import Wrapper from "shared/components/Wrapper"
import Box from "shared/components/Box"
import Bleed from "shared/components/Bleed"
import Card from "shared/components/Card"
import HorizontalRule from "shared/components/HorizontalRule"
import Heading from "shared/components/Heading"
import Text from "shared/components/Text"
import SparkBar from "shared/components/SparkBar"
import Overlay, { OverlayContainer } from "shared/components/Overlay"
import Link from "shared/components/Link"
import { sm, md } from "mill/utils/breakpoints"
import FETCH_LEARNER_ENGAGEMENT_FOR_ACCOUNT from "mill/graphql/FetchLearnerEngagementForAccount"
import FETCH_CURRENT_AND_UPCOMING_CAMPAIGNS from "mill/graphql/FetchCurrentAndUpcomingCampaigns"
import FETCH_PERFORMANCE_UPLIFT_FOR_ACCOUNT from "mill/graphql/FetchPerformanceUpliftForAccount"
import Imgix from "mill/components/Imgix"

const Background = styled.div`
  background: #2A4B55; // this should be colors.admin ?
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2rem;

  @media ${md} {
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 2rem;
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media ${md} {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 2rem;
  }
`

const StyledSupportLink = styled(Link)`
  display: block;
  transition: 0.2s background ease-in;

  &:hover {
    transition: 0.2s background ease-out;
    background: #fbfbfb;

    i {
      color: ${props => props.theme.colors.primary};
      font-weight: 600;
    }
  }

  > div {
    @media ${sm} {
      display: grid;
      align-items: center;
      grid-template-columns: 8rem 1fr;
      grid-column-gap: 2rem;
    }
  }
`

const SupportImage = styled(Box)`
  img {
    max-width: 100%;
    max-height: 10rem;

    @media ${md} {
      max-height: 12rem;
    }
  }
`

const StyledCampaignLink = styled(Link)`
  display: block;
  &:hover {
    transition: 0.2s background ease-in-out;
    background: ${darken(0.015, "#fbfbfb")};

    i {
      color: ${props => props.theme.colors.primary};
      font-weight: 600;
    }
  }
`

const MobileCurrentCampaignsCard = styled(Card)`
  display: none;

  @media ${md} {
    display: block;
  }
`

// temporary component?
const BigStat = styled.p`
  color: ${props => props.theme.colors.tertiary};
  font-size: 9.6rem;
  margin: 0;
  line-height: 1;
  font-weight: 600;

  span {
    padding-left: 0.5rem;
    font-size: 6rem;
  }
`

const EngagementChart = () => {
  const { currentUser: viewer } = useContext(CurrentUserContext)
  const { data } = useQuery(FETCH_LEARNER_ENGAGEMENT_FOR_ACCOUNT, {
    variables: { id: viewer.account.id }
  })

  const uniqueValues = new Set(
    data?.account?.learnerEngagementCounts?.map(c => c.count)
  )
  const noData = uniqueValues.size == 1 && uniqueValues.has(0)

  return (
    <OverlayContainer>
      {noData && (
        <Overlay background={darken(0.03, "#2A4B55")}>
          <Box textAlign="center">
            <Text tone="light" weight="strong">
              Waiting for more data
            </Text>
          </Box>
        </Overlay>
      )}
      <SparkBar animate data={data?.account?.learnerEngagementCounts || []} />
    </OverlayContainer>
  )
}

const PerformanceUplift = () => {
  const { currentUser: viewer } = useContext(CurrentUserContext)
  const { data } = useQuery(FETCH_PERFORMANCE_UPLIFT_FOR_ACCOUNT, {
    variables: { id: viewer.account.id }
  })
  const decimal = data?.accountInsights?.averageQuestionUplift || 0
  const percentage = Math.round(decimal * 100)

  return (
    <BigStat>
      {percentage}
      <span>
        <i className="fa fa-percentage" />
      </span>
    </BigStat>
  )
}

const CurrentAndUpcomingCampaigns = () => {
  const { data, loading } = useQuery(FETCH_CURRENT_AND_UPCOMING_CAMPAIGNS)

  const campaignsPath = "/admin/campaigns"
  const currentCampaignsPath =
    "/admin/campaigns?states=scheduled,warmup,active,cooldown"
  const campaigns = data?.campaigns?.edges || []
  const CardComponent =
    campaigns.length === 0 ? MobileCurrentCampaignsCard : Card
  const moreActiveCampaigns = data?.campaigns?.pageInfo?.hasNextPage
  const viewMoreCampaignsPath = moreActiveCampaigns
    ? currentCampaignsPath
    : campaignsPath
  const modifierCopy = moreActiveCampaigns ? "more upcoming and active" : "all"

  return (
    <CardComponent backgroundColor="#fbfbfb" boxShadow="small">
      <Box
        paddingTop={{ mobile: "small", desktop: "medium" }}
        paddingLeft={{ mobile: "small", desktop: "medium" }}
        paddingRight={{ mobile: "small", desktop: "medium" }}>
        <Box padding={{ mobile: "small", desktop: "medium" }}>
          <Heading level={2}>Current and upcoming campaigns</Heading>
        </Box>

        {loading && (
          <Box paddingLeft="medium" paddingRight="medium">
            <Text>Loading</Text>
          </Box>
        )}

        {campaigns.length === 0 && (
          <Box paddingLeft="medium" paddingRight="medium">
            <Text>No current or upcoming campaigns</Text>
          </Box>
        )}

        {campaigns.map(campaign => {
          return (
            <React.Fragment key={campaign.node.id}>
              <CampaignLink
                id={campaign.node.id}
                title={campaign.node.name}
                date={`${campaign.node.friendlyStartDate} – ${campaign.node.friendlyCompletionDate}`}
                mechanism={campaign.node.mechanism}
                learnerCount={campaign.node.learnerCount}
              />
              <HorizontalRule />
            </React.Fragment>
          )
        })}

        <Box padding="medium" textAlign="right">
          <Text color="primary">
            <Link to={viewMoreCampaignsPath}>
              View {modifierCopy} campaigns{" "}
              <i className="fa fa-long-arrow-right" />
            </Link>
          </Text>
        </Box>
      </Box>
    </CardComponent>
  )
}

const SupportLink = ({ to, heading, text, imagePath }) => {
  return (
    <StyledSupportLink to={to}>
      <Box
        paddingTop="medium"
        paddingBottom="medium"
        paddingLeft={{ mobile: "small", tablet: "medium" }}
        paddingRight={{ mobile: "small", tablet: "medium" }}>
        <SupportImage
          paddingBottom={{ mobile: "medium", tablet: "none" }}
          textAlign={{ mobile: "left", desktop: "center" }}>
          <Imgix srcPath={imagePath} maxHeight="70" maxWidth="70" />
        </SupportImage>
        <div>
          <Text weight="strong" family="heading" size="large" tone="dark">
            {heading} <i className="fa fa-long-arrow-right" />
          </Text>
          <Text>{text}</Text>
        </div>
      </Box>
    </StyledSupportLink>
  )
}

const CampaignLink = ({ id, title, date, mechanism, learnerCount }) => {
  const mechanismColor = mechanism === "embed" ? "primary" : "tertiary"
  return (
    <StyledCampaignLink to={`/admin/campaigns/${id}`}>
      <Box padding={{ mobile: "small", desktop: "medium" }}>
        <Text weight="strong" family="heading" size="large" tone="dark">
          {title} <i className="fa fa-long-arrow-right" />
        </Text>
        <Text>{date}</Text>
        <Text size="small">
          <Text
            component="span"
            size="small"
            color={mechanismColor}
            textTransform="capitalize">
            {mechanism}
          </Text>{" "}
          for {learnerCount} learners
        </Text>
      </Box>
    </StyledCampaignLink>
  )
}

const Dashboard = () => {
  return (
    <React.Fragment>
      <Background>
        <Wrapper>
          <Box paddingBottom="xlarge" paddingTop="small">
            <Grid>
              <Card
                backgroundColor={darken(0.03, "#2A4B55")}
                style={{ maxWidth: "100%" }}>
                <Box padding="medium">
                  <Heading level={2} color="quaternary">
                    Learner engagement
                  </Heading>
                  <Text tone="light">
                    Daily number of unique learners interacting with your
                    content
                  </Text>
                  <Box paddingTop="small">
                    <EngagementChart />
                  </Box>
                </Box>
              </Card>
              <Card backgroundColor={darken(0.03, "#2A4B55")}>
                <Box padding="medium">
                  <PerformanceUplift />
                  <Heading level={2} color="tertiary">
                    Performance uplift
                  </Heading>
                  <Text tone="light">
                    The average uplift in performance between first and second
                    attempts on embed campaigns
                  </Text>
                </Box>
              </Card>
            </Grid>
          </Box>
        </Wrapper>
      </Background>
      <Wrapper>
        <Bleed top="large">
          <Flex>
            <Card>
              <Box padding={{ mobile: "small", desktop: "medium" }}>
                <Box padding={{ mobile: "small", desktop: "medium" }}>
                  <Heading level={2}>Getting the most out of Yarno</Heading>
                  <Text>Set yourself up for success</Text>
                </Box>
                <SupportLink
                  heading="The Yarno method"
                  imagePath="static/yarno_method.png"
                  text="Learn what Yarno is, and why it works - the learner experience and the learning science that underpins our platform."
                  to="https://www.yarno.com.au/support/category/the-yarno-method/"
                />
                <HorizontalRule />
                <SupportLink
                  heading="Learners, teams & groups"
                  imagePath="static/learners_teams_groups.png"
                  text="How to add new learners, edit their details and structure your learners using teams, groups and manager permissions."
                  to="https://www.yarno.com.au/support/category/learners-teams--groups/"
                />
                <HorizontalRule />
                <SupportLink
                  heading="Running campaigns for maximum success"
                  imagePath="static/running_campaigns.png"
                  text="Enable successful learning by setting up embed and burst campaigns."
                  to="https://www.yarno.com.au/support/category/yarno-campaigns/"
                />
                <HorizontalRule />
                <SupportLink
                  heading="Dashboards"
                  imagePath="static/dashboards_insights.png"
                  text="Insights available for campaigns, learners, teams and groups on Yarno performance, allowing you to target the achievement of your goals. "
                  to="https://www.yarno.com.au/support/category/dashboards/"
                />
              </Box>
            </Card>
            <Box marginBottom="small">
              <CurrentAndUpcomingCampaigns />
            </Box>
          </Flex>
        </Bleed>
      </Wrapper>
    </React.Fragment>
  )
}

export default Dashboard
