import { css } from "styled-components"

export const tooltipStyles = css`
  .tooltip {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.8);
    padding: 0.5rem 1rem;
    position: absolute;
    pointer-events: none;
    opacity: 0;
    color: white;
    font-size: 1.2rem;
    strong {
      font-size: 1.5rem;
    }
  }

  .tooltip.active {
    opacity: 1;
    min-width: 100px;
  }

  .tooltip strong {
    display: block;
  }

  .tooltip-rect {
    fill: rgba(0, 0, 0, 0.4);
    width: 100px;
    height: 100px;
    rx: 5;
  }
`

export const decimalToPercentage = num => {
  const percentage = (num * 100).toFixed(2)
  return percentage.replace(/[.,]00$/, "") // removes decimals if x.00%
}

export const toTextCase = text => {
  const result = text.replace(/([A-Z])/g, " $1")
  return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase()
}