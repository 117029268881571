import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import * as d3 from "d3"
import { darken } from "polished"
import useResizeObserver from "shared/hooks/useResizeObserver"
import { tooltipStyles } from "../../utils/chartHelpers"

// Apply styling from the top
const Container = styled.div`
  max-width: 100%;
  overflow: hidden;
  padding-bottom: 2px;

  svg {
    overflow: visible;
    height: 100%;
    position: relative;
  }
  text {
    fill: black;
  }

  .bar-group--bar {
    cursor: pointer;
    fill: ${props => props.theme.colors.quaternary};
    &:hover {
      fill: ${props => darken(0.05, props.theme.colors.quaternary)};
      transition: fill 0.1s ease-in;
    }
  }
  .bar-stumps {
    fill: ${props => props.theme.colors.quaternary};
  }

  ${tooltipStyles}
`

const SparkBar = ({ data, animate }) => {
  const svgRef = useRef(null)
  const chartContainer = useRef(null)
  const dimensions = useResizeObserver(chartContainer)

  useEffect(() => {
    if (data && chartContainer.current) {
      if (!dimensions) return

      const svg = d3
        .select(svgRef.current)
        .attr("width", dimensions.width)
        .attr("height", dimensions.height)
        .style("-webkit-tap-highlight-color", "transparent")
        .style("overflow", "visible")
        .on("touchstart", event => event.preventDefault())

      // this renders twice?
      // Tooltip
      const tooltip = d3
        .select(chartContainer.current)
        .append("div")
        .attr("class", "tooltip")

      svg.selectAll("*").remove()

      svg.append("g")

      const xScale = d3
        .scaleBand()
        .range([0, dimensions.width])
        .domain(data.map(d => d.day))
        .padding(0.2)

      // Add Y axis
      var yScale = d3
        .scaleLinear()
        .domain([0, d3.max(data.map(d => d.count)) || 150])
        .range([dimensions.height, 0])

      // Add blank bar spaces
      svg
        .append("g")
        .attr("class", "bar-stumps")
        .selectAll("bar-stumps")
        .data(data)
        .enter()
        .append("rect")
        .attr("x", function (d) {
          return xScale(d.day)
        })
        .attr("width", xScale.bandwidth())
        .attr("rx", 2)
        .attr("height", function (d) {
          return 2
        }) // always equal to 0
        .attr("y", function (d) {
          return yScale(0)
        })

      // Bars
      svg
        .append("g")
        .attr("class", "bar-group")
        .selectAll("bar-group")
        .data(data)
        .enter()
        .append("rect")
        .attr("class", "bar-group--bar")
        .attr("x", function (d) {
          return xScale(d.day)
        })
        .attr("width", xScale.bandwidth())
        .attr("rx", 2)
        // no bar at the beginning thus:
        .attr("height", function (d) {
          if (animate) {
            return dimensions.height - yScale(0)
          } else {
            return dimensions.height - yScale(d.count)
          }
        }) // always equal to 0
        .attr("y", function (d) {
          if (animate) {
            return yScale(0)
          } else {
            return yScale(d.count) + 2
          }
        })
        .on("mouseover", function (event, d) {
          tooltip
            .style("top", event.offsetY - 10 + "px")
            .style("left", event.offsetX + 10 + "px")
            .classed("active", true)

          tooltip.html(
            `
            <strong>${d.count} learners</strong>
            ${d.day}
            `
          )
        })
        .on("mousemove", function (event, d) {
          tooltip
            .style("top", event.offsetY - 10 + "px")
            .style("left", event.offsetX + 10 + "px")
        })
        .on("mouseout", function (d) {
          tooltip.classed("active", false)
        })

      // Animation
      if (animate) {
        svg
          .selectAll(".bar-group--bar")
          .transition()
          .duration(800)
          .attr("y", function (d) {
            return yScale(d.count) + 2
          })
          .attr("height", function (d) {
            return dimensions.height - yScale(d.count)
          })
          .delay(function (d, i) {
            return i * 5
          })
      }
    }
  }, [data, dimensions])

  return (
    <Container ref={chartContainer}>
      <svg ref={svgRef}></svg>
    </Container>
  )
}

export default SparkBar

SparkBar.defaultProps = {
  color: "quarternary"
}
